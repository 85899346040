<template>
  <div class="confirmation content">
    <Retrieve
      :isConfirmation="true"
    ></Retrieve>
  </div>

</template>

<script lang="ts">
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import Retrieve from '../../cms/canvas/shop/Retrieve.vue';

@Component({
  components: { 
    Retrieve
  },
  computed: {
  }
})
export default class Confirmation extends mixins(GenericPage) {
  
}
</script>